import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  Container,
  Row,
  Col,
  Alert,
  Spinner,
} from "react-bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./styles/ClimateModal.css";

const ClimateModal = ({ showModal, setShowModal, selectedArticle }) => {
  const [copiedValue, setCopiedValue] = useState("");
  const [clickedValue, setClickedValue] = useState("");
  const [reportErrorClicked, setReportErrorClicked] = useState(false);
  const [reportErrorLoading, setReportErrorLoading] = useState(false);

  useEffect(() => {
    if (!showModal) {
      setReportErrorClicked(false);
    }
  }, [showModal]);

  const reportError = async () => {
    setReportErrorLoading(true); // Starta spinner-visualiseringen när du börjar begäran

    try {
      const response = await fetch("/reportError", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ articleID: selectedArticle.finfoarticleid }),
      });

      if (response.ok) {
        setReportErrorClicked(true); // Visa "Rapport skickad" när begäran lyckas
      } else {
        console.error("Failed to report error.");
      }
    } catch (error) {
      console.error("Error reporting the article:", error);
    } finally {
      setReportErrorLoading(false); // Stoppa spinner-visualiseringen när begäran är klar
    }
  };

  if (!selectedArticle) {
    return null;
  }

  const copyToClipboard = (value) => {
    navigator.clipboard.writeText(value);
    setCopiedValue(value);
  };

  const handleIconClick = (value) => {
    copyToClipboard(value);
    setClickedValue(value);
    setTimeout(() => {
      setClickedValue("");
    }, 700);
  };

  const renderLabel = (label) => {
    return (
      <span className="label-field">
        <strong>{label}:</strong> <br />
      </span>
    );
  };

  const renderValueOnly = (value) => {
    if (value) {
      const formattedValue =
        typeof value === "number" ? value.toString().replace(".", ",") : value;
      return (
        <span
          className={`clickable-value ${
            formattedValue === clickedValue ? "icon-clicked" : ""
          }`}
          onClick={() => handleIconClick(formattedValue)}
        >
          {formattedValue}
          <i
            className={`bi bi-clipboard ${
              formattedValue === clickedValue ? "icon-clicked" : ""
            }`}
          ></i>
          <br />
        </span>
      );
    }
    return (
      <span className="disabled-field">
        N/A <br />
      </span>
    );
  };

  return (
    <Modal show={showModal} onHide={() => setShowModal(false)} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>{selectedArticle.productname}</Modal.Title>
      </Modal.Header>
      {new Date(selectedArticle.epd_valid_to) < new Date() && (
        <div className="alert-wrapper">
          <Alert variant="danger">
            {`Observera att miljövarudeklarationen som används för beräkning utgick ${selectedArticle.epd_valid_to}.`}
          </Alert>
        </div>
      )}
      {selectedArticle.epd_comment && (
        <div className="alert-wrapper">
          <Alert variant="warning">{selectedArticle.epd_comment}</Alert>
        </div>
      )}

      <Modal.Body>
        <h5>Artikelindentitet</h5>
        <Container>
          <Container>
            <Row>
              <Col xs={3} md={2}>
                {renderLabel("Leverantör")}
                {renderLabel("Artikelnummer")}
                {renderLabel("GTIN")}
                {renderLabel("Finfo-nummer")}
                {renderLabel("NOBB-nummer")}
                {renderLabel("RSK-nummer")}
              </Col>
              <Col xs={3} md={5}>
                {renderValueOnly(selectedArticle.suppliername)}
                {renderValueOnly(selectedArticle.supplierarticleid)}
                {renderValueOnly(selectedArticle.gtin)}
                {renderValueOnly(selectedArticle.finfoarticleid)}
                {renderValueOnly(selectedArticle.nobb)}
                {renderValueOnly(selectedArticle.rsk)}
              </Col>
              <Col xs={3} md={2}>
                {selectedArticle.epdlink && (
                  <Button
                    variant="outline-secondary"
                    href={selectedArticle.epdlink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="float-right custom-button"
                  >
                    <i className="bi bi-cloud-arrow-down large-icon"></i> Ladda
                    hem EPD
                  </Button>
                )}
              </Col>
            </Row>
          </Container>
          <br />
          <h5>Värden extraherade från miljövarudeklaration (EPD)</h5>
          <br />
          <Container>
            <Row>
              <Col xs={3} md={4}>
                {renderLabel("Vikt på funktionell enhet (kg)")}
                {renderLabel("Funktionell enhet")}
                {renderLabel("EPD-id")}
                {renderLabel("Publisher")}
                {renderLabel("Standard")}
                {renderLabel("Giltig till")}
              </Col>
              <Col xs={3} md={4}>
                {renderValueOnly(selectedArticle.epd_functional_unit_weight)}
                {renderValueOnly(selectedArticle.epd_functional_unit)}
                {renderValueOnly(selectedArticle.epd_id)}
                {renderValueOnly(selectedArticle.epd_publisher)}
                {renderValueOnly(selectedArticle.epd_standard)}
                {renderValueOnly(selectedArticle.epd_valid_to)}
              </Col>
            </Row>
          </Container>
          <br />
          <Row>
            <Col xs={3} md={4}>
              {renderLabel("GWP-GHG A1")}
              {renderLabel("GWP-GHG A2")}
              {renderLabel("GWP-GHG A3")}
              {renderLabel("GWP-GHG A1-A3")}
              {renderLabel("GWP-GHG A4")}
              {renderLabel("GWP-GHG A5")}
            </Col>
            <Col xs={3} md={2}>
              {renderValueOnly(selectedArticle.epd_a1)}
              {renderValueOnly(selectedArticle.epd_a2)}
              {renderValueOnly(selectedArticle.epd_a3)}
              {renderValueOnly(selectedArticle.epd_a1a3)}
              {renderValueOnly(selectedArticle.epd_a4)}
              {renderValueOnly(selectedArticle.epd_a5)}
            </Col>
            <Col xs={3} md={3}>
              {renderLabel("GWP total A1-A3")}
              {renderLabel("GWP total A4")}
              {renderLabel("GWP total A5")}
              {renderLabel("GWP fosil A1-A3")}
              {renderLabel("GWP fosil A4")}
              {renderLabel("GWP fosil A5")}
            </Col>
            <Col xs={3} md={2}>
              {renderValueOnly(selectedArticle.epd_gwp_total_a1a3)}
              {renderValueOnly(selectedArticle.epd_gwp_total_a4)}
              {renderValueOnly(selectedArticle.epd_gwp_total_a5)}
              {renderValueOnly(selectedArticle.epd_gwp_fossil_a1a3)}
              {renderValueOnly(selectedArticle.epd_gwp_fossil_a4)}
              {renderValueOnly(selectedArticle.epd_gwp_fossil_a5)}
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={3} md={4}>
              {renderLabel("GWP luluc A1-A3")}
              {renderLabel("GWP luluc A4")}
              {renderLabel("GWP luluc A5")}
              {renderLabel("GWP biogenic A1-A3")}
              {renderLabel("GWP biogenic A4")}
              {renderLabel("GWP biogenic A5")}
            </Col>
            <Col xs={3} md={2}>
              {renderValueOnly(selectedArticle.epd_gwp_luluc_a1a3)}
              {renderValueOnly(selectedArticle.epd_gwp_luluc_a4)}
              {renderValueOnly(selectedArticle.epd_gwp_luluc_a5)}
              {renderValueOnly(selectedArticle.epd_gwp_biogenic_a1a3)}
              {renderValueOnly(selectedArticle.epd_gwp_biogenic_a4)}
              {renderValueOnly(selectedArticle.epd_gwp_biogenic_a5)}
            </Col>
          </Row>
        </Container>
        <br />
        <h5>Beräknade värden (GWP-GHG)</h5>
        <Container>
          <Row>
            <Col xs={3} md={2}>
              {renderLabel("A1 " + selectedArticle.primunit)}
              {renderLabel("A2 " + selectedArticle.primunit)}
              {renderLabel("A3 " + selectedArticle.primunit)}
              {renderLabel("A1-A3 " + selectedArticle.primunit)}
              {renderLabel("A4 " + selectedArticle.primunit)}
              {renderLabel("A5 " + selectedArticle.primunit)}
            </Col>
            <Col xs={3} md={2}>
              {renderValueOnly(selectedArticle.calc_epd_a1_primary)}
              {renderValueOnly(selectedArticle.calc_epd_a2_primary)}
              {renderValueOnly(selectedArticle.calc_epd_a3_primary)}
              {renderValueOnly(selectedArticle.calc_epd_a1a3_primary)}
              {renderValueOnly(selectedArticle.calc_epd_a4_primary)}
              {renderValueOnly(selectedArticle.calc_epd_a5_primary)}
            </Col>
            <Col xs={3} md={2}>
              {renderLabel("A1 kg")}
              {renderLabel("A2 kg")}
              {renderLabel("A3 kg")}
              {renderLabel("A1-A3 kg")}
              {renderLabel("A4 kg")}
              {renderLabel("A5 kg")}
            </Col>
            <Col xs={3} md={2}>
              {renderValueOnly(selectedArticle.calc_epd_a1_kg)}
              {renderValueOnly(selectedArticle.calc_epd_a2_kg)}
              {renderValueOnly(selectedArticle.calc_epd_a3_kg)}
              {renderValueOnly(selectedArticle.calc_epd_a1a3_kg)}
              {renderValueOnly(selectedArticle.calc_epd_a4_kg)}
              {renderValueOnly(selectedArticle.calc_epd_a5_kg)}
            </Col>
            <Col xs={3} md={2}>
              {renderLabel("A1 m")}
              {renderLabel("A2 m")}
              {renderLabel("A3 m")}
              {renderLabel("A1-A3 m")}
              {renderLabel("A4 m")}
              {renderLabel("A5 m")}
            </Col>
            <Col xs={3} md={2}>
              {renderValueOnly(selectedArticle.calc_epd_a1_m)}
              {renderValueOnly(selectedArticle.calc_epd_a2_m)}
              {renderValueOnly(selectedArticle.calc_epd_a3_m)}
              {renderValueOnly(selectedArticle.calc_epd_a1a3_m)}
              {renderValueOnly(selectedArticle.calc_epd_a4_m)}
              {renderValueOnly(selectedArticle.calc_epd_a5_m)}
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={3} md={2}>
              {renderLabel(
                <span>
                  A1m<sup>2</sup>
                </span>
              )}
              {renderLabel(
                <span>
                  A2 m<sup>2</sup>
                </span>
              )}
              {renderLabel(
                <span>
                  A3 m<sup>2</sup>
                </span>
              )}
              {renderLabel(
                <span>
                  A1-A3 m<sup>2</sup>
                </span>
              )}
              {renderLabel(
                <span>
                  A4 m<sup>2</sup>
                </span>
              )}
              {renderLabel(
                <span>
                  A5 m<sup>2</sup>
                </span>
              )}
            </Col>
            <Col xs={3} md={2}>
              {renderValueOnly(selectedArticle.calc_epd_a1_m2)}
              {renderValueOnly(selectedArticle.calc_epd_a2_m2)}
              {renderValueOnly(selectedArticle.calc_epd_a3_m2)}
              {renderValueOnly(selectedArticle.calc_epd_a1a3_m2)}
              {renderValueOnly(selectedArticle.calc_epd_a4_m2)}
              {renderValueOnly(selectedArticle.calc_epd_a5_m2)}
            </Col>
            <Col xs={3} md={2}>
              {renderLabel("A1 l")}
              {renderLabel("A2 l")}
              {renderLabel("A3 l")}
              {renderLabel("A1-A3 l")}
              {renderLabel("A4 l")}
              {renderLabel("A5 l")}
            </Col>
            <Col xs={3} md={2}>
              {renderValueOnly(selectedArticle.calc_epd_a1_l)}
              {renderValueOnly(selectedArticle.calc_epd_a2_l)}
              {renderValueOnly(selectedArticle.calc_epd_a3_l)}
              {renderValueOnly(selectedArticle.calc_epd_a1a3_l)}
              {renderValueOnly(selectedArticle.calc_epd_a4_l)}
              {renderValueOnly(selectedArticle.calc_epd_a5_l)}
            </Col>
          </Row>
        </Container>
        <br />
        <h5>Boverket</h5>
        <Container>
          <Row>
            <Col xs={3} md={2}>
              {renderLabel("Resursid")}
            </Col>
            <Col xs={3} md={2}>
              {renderValueOnly(selectedArticle.resource_id)}
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={3} md={2}>
              {renderLabel("A1-A3 " + selectedArticle.primunit)}
              {renderLabel("A4 " + selectedArticle.primunit)}
              {renderLabel("A5 " + selectedArticle.primunit)}
            </Col>
            <Col xs={3} md={2}>
              {renderValueOnly(selectedArticle.a1a3_primary_pack)}
              {renderValueOnly(selectedArticle.a4_primary_pack)}
              {renderValueOnly(selectedArticle.a5_primary_pack)}
            </Col>
            <Col xs={3} md={2}>
              {renderLabel("A1-A3 kg")}
              {renderLabel("A4 kg")}
              {renderLabel("A5 kg")}
            </Col>
            <Col xs={3} md={2}>
              {renderValueOnly(selectedArticle.a1a3_kg)}
              {renderValueOnly(selectedArticle.a4_kg)}
              {renderValueOnly(selectedArticle.a5_kg)}
            </Col>
            <Col xs={3} md={2}>
              {renderLabel("A1-A3 m")}
              {renderLabel("A4 m")}
              {renderLabel("A5 m")}
            </Col>
            <Col xs={3} md={2}>
              {renderValueOnly(selectedArticle.a1a3_meter)}
              {renderValueOnly(selectedArticle.a4_meter)}
              {renderValueOnly(selectedArticle.a5_meter)}
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={3} md={2}>
              {renderLabel(
                <span>
                  A1-A3 m<sup>2</sup>
                </span>
              )}
              {renderLabel(
                <span>
                  A4 m<sup>2</sup>
                </span>
              )}
              {renderLabel(
                <span>
                  A5 m<sup>2</sup>
                </span>
              )}
            </Col>
            <Col xs={3} md={2}>
              {renderValueOnly(selectedArticle.a1a3_m2)}
              {renderValueOnly(selectedArticle.a4_m2)}
              {renderValueOnly(selectedArticle.a5_m2)}
            </Col>
            <Col xs={3} md={2}>
              {renderLabel("A1-A3 l")}
              {renderLabel("A4 l")}
              {renderLabel("A5 l")}
            </Col>
            <Col xs={3} md={2}>
              {renderValueOnly(selectedArticle.a1a3_l)}
              {renderValueOnly(selectedArticle.a4_l)}
              {renderValueOnly(selectedArticle.a5_l)}
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Container>
          <Row className="mb-3">
            <Col>
              Alla presenterade värden är i enheten kg CO<sub>2</sub>e
            </Col>
          </Row>

          <hr />

          <Row className="mb-3">
            <Col>
              Vår klimatdata genereras genom en kombination av direkt inhämtning
              och beräkningar baserade på uppgifter som leverantörerna
              tillhandahåller. Medan vi ständigt strävar efter att säkerställa
              precision, är det viktigt att förstå att fel i leverantörens
              uppgifter kan replikeras i våra klimatresultat. Dessa
              felaktigheter är ovanliga, men möjliga. Om du upptäcker några
              avvikelser, värdesätter vi din noggrannhet och hjälp med att
              rapportera dem.
            </Col>
            <Col xs="auto">
              <Button
                style={{ marginRight: "20px" }}
                variant="outline-secondary"
                onClick={reportError}
                disabled={reportErrorClicked || reportErrorLoading}
              >
                {reportErrorLoading ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    <span className="ml-2">Skickar...</span>
                  </>
                ) : reportErrorClicked ? (
                  "Rapport skickad"
                ) : (
                  "Rapportera avvikande data"
                )}
              </Button>
            </Col>
          </Row>

          <hr />

          <Row>
          <Col xs="auto" className="right-align">
  <Button
    variant="outline-secondary"
    onClick={() => setShowModal(false)}
  >
    Stäng
  </Button>
</Col>


          </Row>
        </Container>
      </Modal.Footer>
    </Modal>
  );
};

export default ClimateModal;
