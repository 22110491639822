import React, { useState, useEffect } from "react";
import "./styles/Counter.css";  // Säkerställ att sökvägen är korrekt

const Counter = ({ target, label }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    let start = 0;
    const end = target;
    if (start === end) return;

    let stepTime = Math.abs(Math.floor(3000 / (end - start)));

    let obj = { val: start };
    let timestamp = Date.now();

    const tick = () => {
      let now = Date.now();
      let progress = Math.min((now - timestamp) / 3000, 1);

      obj.val = Math.floor(progress * (end - start) + start);

      if (obj.val === end) {
        cancelAnimationFrame(tick);
      }

      setCount(obj.val);
      requestAnimationFrame(tick);
    };

    tick();
  }, [target]);

  return (
    <div className="counter-box">
      <h4 className="counter-label">{label}</h4>  {/* Här har jag lagt till din CSS-klass */}
      <h1 className="counter-value">{count.toLocaleString("sv-SW")}</h1>  {/* Och här */}
    </div>
  );
};

export default Counter;
