import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar, Nav, Button, Badge, Modal } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import "./styles/Navbar.css";
import FinfoLogoWhite from '../images/finfoLogoWhite.png';

const CustomNavbar = ({ onLogin, onLogout }) => {
  const { isAuthenticated, loginWithRedirect, logout, user } = useAuth0();
  const navigate = useNavigate();

  // Funktion för att hämta användarens fullständiga namn baserat på e-postadress
  const getUserName = (email) => {
    const nameParts = email.split('@')[0].split('.');  // Dela upp namnet vid punkter
    return nameParts.map(part => part.charAt(0).toUpperCase() + part.slice(1)).join(' ');  // Sätt varje ord med stor inledande bokstav, följt av små bokstäver
  };

  const navigateAndRefresh = () => {
    navigate('/');
    window.location.reload();
  };


  return (
    <>
      <Navbar className="custom-navbar" expand="lg">
      <Navbar.Brand onClick={navigateAndRefresh} style={{ paddingLeft: '20px', cursor: 'pointer' }}>
  <img src={FinfoLogoWhite} alt="Finfo Klimat Logo" style={{ height: '30px' }} />
</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto pr-3">
            {isAuthenticated ? (
              <>
                <Badge variant="test" className="d-flex align-items-center" bg="secondary" style={{ fontSize: '1rem', marginRight: '10px', marginLeft: '10px' }}>
                  {getUserName(user.email)}  {/* Använd den uppdaterade funktionen här */}
                </Badge>
                <Button variant="outline-light" onClick={() => { logout({ returnTo: window.location.origin }); onLogout(); }}>Logga ut</Button>
              </>
            ) : (
              <Button variant="outline-light" onClick={() => { loginWithRedirect(); onLogin(); }}>Logga in</Button>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default CustomNavbar;
