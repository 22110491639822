import React from "react";
import "./styles/Footer.css";
import FinfoLogoBlue from '../images/finfoLogoBlue.png';

function Footer() {
  return (
    <div className="footerStyle">
      <div className="firstColStyle">
        <h4>Vägen till hållbart byggande</h4>
        <p>
          Finfo klimat är ensamt på marknaden om att erbjuda kostnadsfri och
          publikt tillgänglig information om klimatdata nödvändig för
          klimatdeklaration till Boverket.
        </p>
        <img src={FinfoLogoBlue} alt="Finfo Klimat Logo" style={{ height: '30px' }} />
      </div>
      <div className="otherColStyle">
        <strong>Verktyg</strong>
        <div>
          <a
            href="https://www2.finfo.se/tjanster/mottagare/jamfor-och-visa-produkters-koldioxidekvivalent/"
            className="link-style"
          >
            Mer om Finfo Klimat
          </a>
        </div>
        <div>
          <a
            href="https://api.finfo.se/docs/v1/subscriber.html"
            className="link-style"
          >
            Klimatdata via API
          </a>
        </div>
        <div>
          <a
            href="https://www.boverket.se/sv/klimatdeklaration/"
            className="link-style"
          >
            Boverkets digitala handbok
          </a>
        </div>
      </div>
      <div className="otherColStyle">
        <strong>Andra produkter</strong>
        <div>
          <a
            href="https://www2.finfo.se/tjanster/mottagare/finfo-artikel/"
            className="link-style"
          >
            Masterdata
          </a>
        </div>
        <div>
          <a
            href="https://www2.finfo.se/tjanster/mottagare/finfo-media-central/"
            className="link-style"
          >
            Marknadsmaterial
          </a>
        </div>
        <div>
          <a
            href="https://www2.finfo.se/tjanster/mottagare/alla-miljobedomningar-pa-ett-stalle/"
            className="link-style"
          >
            Miljömärkningar
          </a>
        </div>
      </div>
      <div className="otherColStyle">
        <strong>Om oss</strong>
        <div>
          <a href="https://www2.finfo.se/kontakt/" className="link-style">
            Kontakt
          </a>
        </div>
        <div>
          <a
            href="https://www2.finfo.se/utbildningar-oversikt/"
            className="link-style"
          >
            Utbildningar
          </a>
        </div>
        <div>
          <a href="https://www2.finfo.se/nyheter/" className="link-style">
            Nyheter
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
