import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Auth0Provider } from '@auth0/auth0-react';

const root = ReactDOM.createRoot(document.getElementById('root'));

const Main = () => {
  const [auth0Config, setAuth0Config] = useState(null);

  useEffect(() => {
    // Hämta Auth0-konfiguration från server
    fetch('/auth0config')
      .then(response => response.json())
      .then(config => {
        config.redirectUri = window.location.origin; // Lägg till redirectUri här
        setAuth0Config(config);
      });
  }, []);

  if (!auth0Config) return null; // Rendera ingenting om konfigurationen inte är laddad än

  return (
    <React.StrictMode>
      <Auth0Provider {...auth0Config}>
        <App />
      </Auth0Provider>
    </React.StrictMode>
  );
};

root.render(<Main />);
reportWebVitals();
