import React, { useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
import Cookies from "js-cookie";
import './styles/CookieConsent.css'; // Behåll referensen till den tidigare skapade CSS-filen

function CookieConsent() {
    const [show, setShow] = useState(!Cookies.get('cookieConsent'));

    const handleAccept = () => {
        Cookies.set('cookieConsent', 'accepted', { expires: 365 });
        setShow(false);
    };

    return (
        show && (
            <div className="cookie-overlay">
                <Alert variant="dark" className="cookie-alert">
                <div className="cookie-text-container"> 
                Denna webbplats använder cookies för att förbättra din upplevelse, spåra besöksstatistik och för marknadsföringsändamål. Genom att fortsätta använda denna webbplats godkänner du vår användning av cookies.
                </div>
                    <Button onClick={handleAccept} variant="light" size="sm" className="ml-2">
                        Jag förstår
                    </Button>
                </Alert>
            </div>
        )
    );
}

export default CookieConsent;
