import React, { useState, useEffect, useRef } from "react";
import Cookies from "js-cookie";
import { Button, Modal, Form, Card } from "react-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";
import ClimateModal from "./ClimateModal";
import "./styles/SearchComponent.css";
import Counter from "./Counter";
import placeholderImage from "../images/placeholder.png";
import { autocomplete, getAlgoliaResults } from '@algolia/autocomplete-js';


const SearchComponent = ({ onSearch }) => {
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [searchCount, setSearchCount] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [showLimitModal, setShowLimitModal] = useState(false);
  const [searchDisabled, setSearchDisabled] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);
  const containerRef = useRef(null);
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const autocompleteInstance = useRef(null);



  useEffect(() => {
    const count = Cookies.get("searchCount") || 0;
    setSearchCount(Number(count));
    if (isAuthenticated) {
      setSearchDisabled(false);
      Cookies.set("searchCount", 0, { expires: 1 });
    } else if (Number(count) >= 3) {
      setSearchDisabled(true);
    }
  }, [isAuthenticated]);


  console.log('Component is mounting or updating');
  useEffect(() => {
    console.log('Running useEffect');
    if (!containerRef.current) return;
  
    console.log("Creating a new autocomplete instance");
  
    if (autocompleteInstance.current) {
      console.log("Destroying the existing autocomplete instance");
      autocompleteInstance.current.destroy();
    }
  
    autocompleteInstance.current = autocomplete({
      container: containerRef.current,
      getSources: async ({ query }) => {
          if (!query) return [];
          try {
              const response = await fetch('/search', {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json' },
                  body: JSON.stringify({ query }),
              });
              if (!response.ok) throw new Error('Search failed');
              const hits = await response.json();
              console.log(hits);

              return [
                  {
                      sourceId: 'products',
                      getItems: () => hits,
                      templates: {
                          item: ({ item }) => item.productname,
                      },
                  },
              ];
          } catch (error) {
              console.error(error);
              return [];
          }
      },
  });

  return () => {
    console.log("Destroying the autocomplete instance");
    if (autocompleteInstance.current) autocompleteInstance.current.destroy();
  };
}, []); // Debugga: Lägg till rätt beroenden här om några och se om useEffect körs flera gånger.
console.log('Component has mounted or updated');

  
  const handleSearch = async () => {
    if (query && (isAuthenticated || searchCount < 3)) {
      try {
        const response = await fetch("/search", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ query }),
        });

        const hits = await response.json();
        setResults(hits);
        setHasSearched(true);
        onSearch();

        if (!isAuthenticated) {
          const newCount = searchCount + 1;
          Cookies.set("searchCount", newCount, { expires: 1 });
          setSearchCount(newCount);

          if (newCount >= 3) {
            setSearchDisabled(true);
            setShowLimitModal(true);
            setQuery("");
          }
        }
      } catch (error) {
        console.error("Error during search:", error);
        // You might want to show some error notification to the user here
      }
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const handleArticleClick = (article) => {
    setSelectedArticle(article);
    setShowModal(true);
  };

  return (

    <div className={`search-container ${results.length > 0 ? "searched" : ""}`}>
      <Form.Control
        className="search-input"
        type="text"
        size="lg"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        onKeyPress={handleKeyPress}
        placeholder={
          isAuthenticated
            ? "Sök klimatdata via artikelbenämning, artikelnummer eller gtin..."
            : searchDisabled
            ? "Logga in för att göra fler sökningar"
            : "Sök klimatdata via artikelbenämning, artikelnummer eller gtin..."
        }
        disabled={searchDisabled}
      />
      <div ref={containerRef} />

      {!hasSearched && (
        <div className="counter-container">
          {/* <Counter
            target={1801234}
            label={
              <>
                Totalt antal artiklar
                <br />i Finfo-databasen
              </>
            }
          /> */}
          <Counter
            target={99787}
            label={
              <>
                Artiklar med specifik
                <br />
                klimatdata från EPD
              </>
            }
          />
          <Counter
            target={53029}
            label={
              <>
                Artiklar med generisk klimatdata
                <br />
                från Boverket
              </>
            }
          />
        </div>
      )}

      {!hasSearched && (
        <div className="info-text-container">
          <p>
            I takt med ökade samhälleliga krav på dokumentation av produkters
            miljöpåverkan blir det essentiellt att välja produkter baserat på
            deras koldioxidavtryck. Finfo Klimat är en innovativ tjänst som
            möjliggör detta genom att tillhandahålla specifika
            koldioxidekvivalenter från EPD:er, och även integrera
            koldioxidekvivalenter från Boverkets generiska klimatdatabas direkt
            kopplat till produkterna.
          </p>
          <br />
          <p>
            En miljövarudeklaration, eller EPD, detaljerar en produkts
            miljöpåverkan under hela dess livscykel, från råmaterial till
            tillverkning, transport, användning och slutligen bortskaffande.
            Genom standardiserade metoder kan omfattande information om
            produktens miljö- och klimatpåverkan samlas in.
          </p>
          <br />
          <p>
            CO2e, eller koldioxidekvivalent, är en måttenhet som används för att
            jämföra utsläpp av olika växthusgaser. I byggsektorn är den särskilt
            viktig då den möjliggör jämförelser av koldioxidutsläpp mellan olika
            byggmaterial och produkter.
          </p>
          <br />
          <p>
            Sedan den 1 januari 2022, då en ny lag trädde i kraft, krävs
            klimatdeklarationer vid uppförande av nya byggnader. Detta för att
            byggherrar ska kunna redovisa och därmed minimera klimatpåverkan
            från byggprocessen.
          </p>
          <br />
          <p>
            <strong>Specifik klimatdata:</strong> Vår specialiserade personal
            samlar noggrant in information från publicerade
            miljövarudeklarationer (EPD) i MediaCentral. När en EPD innehåller
            flera dataset, arbetar vi tillsammans med leverantören för att
            koppla rätt dataset till rätt produkt.
          </p>

          <p>
            <strong>Generisk klimatdata:</strong> Utöver detta tillhandahåller
            Finfo Klimat generisk klimatdata baserat på Boverkets databas. Genom
            detaljerad intern varukategorisering matchar Finfo produkter med
            Boverkets resurser, vilket resulterar i beräknade mängder
            koldioxidekvivalenter per produkt, redovisade i relevanta enheter
            som kvadratmeter, meter eller liter.
          </p>
        </div>
      )}

      <div className="results">
        {hasSearched && results.length === 0 && (
          <p className="no-results-text">
            {" "}
            "{query}" matchade inga produkter i klimatdatabasen.
          </p>
        )}

        <div className="d-flex flex-wrap">
          {results.map((item) => (
            <Card
              key={item.objectID}
              style={{ width: "16rem", margin: "1rem" }}
              onClick={() => handleArticleClick(item)}
            >
              <div className="card-img-container">
                <Card.Img
                  className="card-img-custom"
                  variant="top"
                  src={
                    item.thumbnaillink ? item.thumbnaillink : placeholderImage
                  }
                  alt={item.productname}
                />
              </div>

              <Card.Body>
                <Card.Title style={{ fontSize: "0.8rem" }}>
                  {item.productname} <br /> <br />
                  {item.suppliername}
                </Card.Title>
                <Card.Text
                  className="card-text-left"
                  style={{ fontSize: "0.8rem" }}
                >
                  <strong>GTIN:</strong> {item.gtin} <br />
                  <strong>Artikelnummer:</strong> {item.supplierarticleid}
                </Card.Text>
              </Card.Body>
            </Card>
          ))}
        </div>
      </div>

      <ClimateModal
        showModal={showModal}
        setShowModal={setShowModal}
        selectedArticle={selectedArticle}
      />
      <Modal show={showLimitModal} onHide={() => setShowLimitModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Daglig begränsning nådd</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Tjänsten är begränsad till tre sökningar om dagen. Registrera dig för
          att få tillgång till obegränsat antal sökningar. Dina uppgifter kan
          komma att användas i marknadsföringssyfte.
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="btn btn-outline-secondary"
            onClick={loginWithRedirect}
          >
            Skapa konto / Logga in
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SearchComponent;
