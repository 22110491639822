import React, { useState } from "react";
import CustomNavbar from "./components/Navbar";
import SearchComponent from "./components/SearchComponent";
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import Footer from "./components/Footer";
import KlimatImage from "./images/klimatWhite.png";
import CookieConsent from "./components/CookieConsent";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);

  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
  };

  const handleSearch = () => {
    setHasSearched(true);
  };

  return (
    <Router>
      <div className="App">
        <CookieConsent />
        <CustomNavbar
          isLoggedIn={isLoggedIn}
          onLogin={handleLogin}
          onLogout={handleLogout}
        />
        <header
          className={`App-header ${
            hasSearched ? "header-animate-end" : "header-animate-start"
          }`}
        >
          <div className={`logo ${hasSearched ? "hide-logo" : ""}`}>
            <img src={KlimatImage} width="150" />
          </div>

          <p className="bold-text">FINFO KLIMAT</p>
          <div className="container">
            <p className="welcome-text">
              Välkommen till Finfos tjänst för klimatdata anpassad för
              deklaration till Boverket. Datan är extraherad av specialister och
              i vissa fall beräknad baserat på den informationen som finns i
              miljövarudeklarationen (EPD). Finner du denna data användbar och behöver en direktkoppling
              till ditt system? <br /> Hör av dig till oss på{" "}
              <a href="mailto:info@finfo.se">info@finfo.se</a>
            </p>
            <SearchComponent isLoggedIn={isLoggedIn} onSearch={handleSearch} />
          </div>
        </header>
        <div className="footer-container">
          <Footer />
        </div>
      </div>
    </Router>
  );
}

export default App;
